import { Stack, Typography, Grid, Box, useMediaQuery } from "@mui/material";
import ProductModal from "../modal/ProductModal";
import { useState } from "react";
import Fertilizers from "../../assets/fertilizers.json";
import ImageBox from "../imagebox/ImageBox";

const Row = ({ title, row, row_title }) => {
  const below_medium_resolution = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );

  const [product, setProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const handleModalClose = () => setOpen(false);

  let info = Fertilizers.find((fertilizer) => fertilizer.title === title);

  return (
    <>
      <Stack>
        {row_title && (
          <Box justifyContent="center" display="flex">
            <Typography
              sx={{
                backgroundColor: "#36ad2d",
                color: "#fff",
                borderRadius: "5px",
                width: "200px",
                p: 1,
              }}
              align="center"
            >
              {row_title}
            </Typography>
          </Box>
        )}

        <Box>
          <Grid
            container
            maxWidth="lg"
            spacing={5}
            display="flex"
            justifyContent="center"
          >
            {info.rows[row].columns.map((prod, index) => {
              let sm_width = 12 / info.rows[row].columns.length;
              console.log(info.rows[row].columns);
              console.log(sm_width, prod);
              return (
                <Grid
                  item
                  key={index}
                  xs={6}
                  sm={sm_width > 10 ? 7 : sm_width}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setProduct(prod);
                    setOpen(true);
                  }}
                >
                  <Stack height="100%">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <ImageBox
                        alt={prod.analysis}
                        sx={{
                          width: prod.image_width
                            ? below_medium_resolution
                              ? "100%"
                              : prod.image_width
                            : "100%",
                        }}
                        src={require(`../../assets/images/${prod.image_filename}`)}
                      />
                    </Box>

                    <Box
                      height="100%"
                      display="flex"
                      alignItems="end"
                      justifyContent="center"
                    >
                      <Typography
                        variant="body1"
                        align="center"
                        color="primary"
                        sx={{ fontWeight: "bold" }}
                      >
                        {prod.title}
                        {prod.subscript && <sup>®</sup>}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Stack>

      {product && (
        <ProductModal
          product={product}
          open={open}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
};

export default Row;
