import { Typography, Container, useMediaQuery } from "@mui/material";
import ProductTab from "../components/tabs/ProductTabs";

const tab_info = {
  title: "Fertilizer Products",
  url: "Fertilizers",
  tabs: [
    {
      value: "Greenskeeper's Secret Turf Management Programs",
      url: "Greenskeepers-Secret-Turf-Management-Programs",
      description:
        "Greenskeeper’s Secret Turf Management Program uses the highest quality raw materials which makes it competitive with the top quality programs on the market. The program offers products to take care of all of your customers fertilization needs, all year long. The high end, retail quality bag makes it easy and convenient for shoppers and will keep them coming back.",
      index: 0,
      rows: [
        { row: 0, sub_title: "PROGRAM 1 (25lbs)" },
        { row: 1, sub_title: "PROGRAM 2 (40lbs)" },
      ],
      row_title: "management_programs",
    },
    {
      value: "Greenskeeper's Secret Premium Blends",
      url: "Greenskeepers-Secret-Premium-Blends",
      description:
        "Greenskeeper’s Secret Premium Blends line of products come in a variety of sizes and analyses. With retail quality bags in a wide variety of sizes and all of the most commonly requested analyses, you will be able to cover all of your customers fertilization needs.",
      index: 1,
      rows: [{ row: 0 }, { row: 1 }, { row: 2 }],
      row_title: "premium_blends",
    },
    {
      value: "Commercial Fertilizer",
      url: "Commercial-Fertilizer",
      description:
        "Bruce Oakley’s Commercial Fertilizer line comes in a variety of stock formulations that are most commonly used and requested.",
      index: 2,
      rows: [{ row: 0 }, { row: 1 }],
      row_title: "commercial_fertilizer",
    },
    {
      value: "Custom Blended Fertilizer",
      url: "Custom-Blended-Fertilizer",
      description:
        "Bruce Oakley's Custom Blended Fertilizer can come in any analysis that you choose. We also offer a large variety of micronutrients which are readily available upon request. Custom blends are available in a 50# poly bag as well as bulk totes.",
      index: 3,
      rows: [{ row: 0 }],
      row_title: "custom_blend",
    },
  ],
};

const Fertilizer = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>
        {!matches && (
          <Typography align="center" variant="h4" mb={5}>
            Fertilizer Products
          </Typography>
        )}

        <ProductTab tab_info={tab_info} />
      </Container>
    </>
  );
};

export default Fertilizer;
